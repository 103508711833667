import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-dashboard-icon',
    standalone: true,
    imports: [CommonModule],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                clip-rule="evenodd"
                d="M1.5 4.73877C1.5 3.35806 2.61929 2.23877 4 2.23877H5.875C7.25571 2.23877 8.375 3.35806 8.375 4.73877V6.61377C8.375 7.99448 7.25571 9.11377 5.875 9.11377H4C2.61929 9.11377 1.5 7.99448 1.5 6.61377V4.73877ZM9.625 4.73877C9.625 3.35806 10.7443 2.23877 12.125 2.23877H14C15.3807 2.23877 16.5 3.35806 16.5 4.73877V6.61377C16.5 7.99448 15.3807 9.11377 14 9.11377H12.125C10.7443 9.11377 9.625 7.99448 9.625 6.61377V4.73877ZM1.5 12.8638C1.5 11.4831 2.61929 10.3638 4 10.3638H5.875C7.25571 10.3638 8.375 11.4831 8.375 12.8638V14.7388C8.375 16.1195 7.25571 17.2388 5.875 17.2388H4C2.61929 17.2388 1.5 16.1195 1.5 14.7388V12.8638ZM9.625 12.8638C9.625 11.4831 10.7443 10.3638 12.125 10.3638H14C15.3807 10.3638 16.5 11.4831 16.5 12.8638V14.7388C16.5 16.1195 15.3807 17.2388 14 17.2388H12.125C10.7443 17.2388 9.625 16.1195 9.625 14.7388V12.8638Z"
                fill-rule="evenodd" />
        </svg>
    `,
    styles: [],
})
export class DashboardIconComponent {}
